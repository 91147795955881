// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

nav.navbar.position-fixed.scrolled{
    box-shadow: 0px 2px 10px 0px lightgray!important;
}

.dropdown-item:hover, .dropdown-item:focus, .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: $primary!important;
}

.dropdown-item.active, .dropdown-item:active {
    color:$primary!important;
    text-decoration: none!important;
    background-color: rgba(0, 0, 0, 0.1)!important;
    }

.badge-primary-1{
    border: 1px solid $primary!important;
}

.primary-border-1{
    border:1px solid $primary!important;
}
.primary-border-2{
    border:2px solid $primary!important;
}
.primary-border-3{
    border:3px solid $primary!important;
}

.table-borderless .badge-primary-1{
    border:none!important;
}

.table-borderless .badge-danger{
    background-color: transparent!important;
}

.table-borderless tbody tr{
    font-size:0.8rem;
}

#myTab a:not(.active){
    color:#9a9898;
  }

@media (max-width: 500px) {
    #myTab {
      flex-wrap:wrap;
      .nav-item{
          width:100%;
      }
    }
}

/*
body{
    padding-top:50px!important;
}

@media (max-width: 600px) {
    body{
        padding-top:70px!important;
    } 
}


.navbar-container::before{
    content:"<a href=''>AKTUELL: Einschränkungen unserer Leistungen bedingt durch COVID-19! Weiterlesen...</a>";
    min-width:100%;
    position:absolute;
    top:-50px;
    text-align:center;
    background-color: #ff1a43;
    padding:10px;
    font-weight: bold;
    color:white;
}

@media (max-width: 600px) {
    .navbar-container::before{
        top:-70px;
    } 
}
*/

.covid-notification{
    max-width: 500px;
    text-align:left;
    background: rgb(255,26,67);
    background: linear-gradient(140deg, rgba(255,26,67,1) 0%, rgb(233, 182, 150) 100%);
    padding:30px;
    margin: auto;
    margin-bottom:50px;
    font-weight: bold;
    color:white;
    border-radius:20px;
    //box-shadow:  27px 27px 53px rgba(255,26,67, 0.30);

    a{
        color:white;
        text-decoration: underline;
    }
}

@media (max-width: 600px) {
    .covid-notification{
        margin-left:5%;
        margin-right:5%;
    } 
}

